/* Base variables */
$screen-lg      : 1200px;

$screen-md-max  : $screen-lg - 1;
$screen-md      : 992px;
$screen-md-min  : $screen-md; 

$screen-sm-max  : $screen-md - 1;
$screen-sm      : 768px;
$screen-sm-min  : $screen-sm; 

$screen-xs-max  : $screen-sm - 1;
$screen-xs      : 480px;
$screen-xs-min  : $screen-xs; 

$border-color: #dfdfdf;
$border-secondary: #dde6fd;

$color-primary: #436dda;
$color-secondary: #f6fcff;
$color-tertiary : #74a901;
$color-quaternary: #56babc;

$color-success	: #5cb85c;
$color-info		: #5bc0de;
$color-warning	: #f0ad4e;
$color-danger	: #fb0d1b; //darken(#D66066, 10%);

$background-color: #f9f9f9;
$color-autopay: #2F5688;
// Override Bootstrap variables here (defaults from bootstrap-sass v3.3.6):

//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:             #000;
$gray-darker:			lighten($gray-base, 13.5%); // #222
$gray-dark:            	lighten($gray-base, 20%); // #333
$gray:					lighten($gray-base, 33.5%); // #555
$gray-light:            lighten($gray-base, 40%); 
$gray-lighter:			#9d9d9d;

// gray darker
@if variable-exists(color-gray-darker) {
  $gray-darker:			$color-gray-darker;
} 
// gray dark
@if variable-exists(color-gray-dark) {
  $gray-dark:			$color-gray-dark;
} 
// gray
@if variable-exists(color-gray) {
  $gray:				$color-gray;
} 
// gray light
@if variable-exists(color-gray-light) {
  $gray-light:			$color-gray-light;
} 
// gray lighter
@if variable-exists(color-gray-lighter) {
  $gray-lighter:		$color-gray-lighter;
} 


$brand-primary:         $color-primary;
$brand-success:         $color-success;
$brand-info:            $color-info;
$brand-warning:         $color-warning;
$brand-danger:          $color-danger;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
// $body-bg:               #fff
//** Global text color on `<body>`.
$text-color:            $gray-base;

//** Global textual link color.
$link-color:            $brand-primary;
//** Link hover color set via `darken()` function.
// $link-hover-color:      darken($link-color, 15%)
//** Link hover decoration.
// $link-hover-decoration: underline


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  'Work Sans', sans-serif;
// $font-family-serif:       Georgia, "Times New Roman", Times, serif
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
// $font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace
// $font-family-base:        $font-family-sans-serif

$font-size-base:          10px;
// $font-size-large:         ceil(($font-size-base * 1.25)) // ~18px
// $font-size-small:         ceil(($font-size-base * 0.85)) // ~12px

// $font-size-h1:            floor(($font-size-base * 2.6)) // ~36px
// $font-size-h2:            floor(($font-size-base * 2.15)) // ~30px
// $font-size-h3:            ceil(($font-size-base * 1.7)) // ~24px
// $font-size-h4:            ceil(($font-size-base * 1.25)) // ~18px
// $font-size-h5:            $font-size-base
// $font-size-h6:            ceil(($font-size-base * 0.85)) // ~12px

//** Unit-less `line-height` for use in components like buttons.
// $line-height-base:        1.428571429 // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
// $line-height-computed:    floor(($font-size-base * $line-height-base)) // ~20px

//** By default, this inherits from the `<body>`.
// $headings-font-family:    inherit
// $headings-font-weight:    500
// $headings-line-height:    1.1
// $headings-color:          inherit


//== Iconography
//
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

//** Load fonts from this directory.

// [converter] If $bootstrap-sass-asset-helper if used, provide path relative to the assets load path.
// [converter] This is because some asset helpers, such as Sprockets, do not work with file-relative paths.
// $icon-font-path: if($bootstrap-sass-asset-helper, "bootstrap/", "../fonts/bootstrap/")

//** File name for all font files.
// $icon-font-name:          "glyphicons-halflings-regular"
//** Element ID within SVG icon file.
// $icon-font-svg-id:        "glyphicons_halflingsregular"


//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

// $padding-base-vertical:     6px
// $padding-base-horizontal:   12px

// $padding-large-vertical:    10px
// $padding-large-horizontal:  16px

// $padding-small-vertical:    5px
// $padding-small-horizontal:  10px

// $padding-xs-vertical:       1px
// $padding-xs-horizontal:     5px

// $line-height-large:         1.3333333 // extra decimals for Win 8.1 Chrome
// $line-height-small:         1.5

// $border-radius-base:        4px
// $border-radius-large:       6px
// $border-radius-small:       3px

//** Global color for active items (e.g., navs or dropdowns).
// $component-active-color:    #fff
//** Global background color for active items (e.g., navs or dropdowns).
// $component-active-bg:       $brand-primary

//** Width of the `border` for generating carets that indicator dropdowns.
// $caret-width-base:          4px
//** Carets increase slightly in size for larger components.
// $caret-width-large:         5px


//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
// $table-cell-padding:            8px
//** Padding for cells in `.table-condensed`.
// $table-condensed-cell-padding:  5px

//** Default background color used for all tables.
// $table-bg:                      transparent
//** Background color used for `.table-striped`.
// $table-bg-accent:               #f9f9f9
//** Background color used for `.table-hover`.
// $table-bg-hover:                #f5f5f5
// $table-bg-active:               $table-bg-hover

//** Border color for table and cell borders.
// $table-border-color:            #ddd


//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

// $btn-font-weight:                normal

// $btn-default-color:              #333
// $btn-default-bg:                 #fff
// $btn-default-border:             #ccc

// $btn-primary-color:              #fff
// $btn-primary-bg:                 $brand-primary
// $btn-primary-border:             darken($btn-primary-bg, 5%)

// $btn-success-color:              #fff
// $btn-success-bg:                 $brand-success
// $btn-success-border:             darken($btn-success-bg, 5%)

// $btn-info-color:                 #fff
// $btn-info-bg:                    $brand-info
// $btn-info-border:                darken($btn-info-bg, 5%)

// $btn-warning-color:              #fff
// $btn-warning-bg:                 $brand-warning
// $btn-warning-border:             darken($btn-warning-bg, 5%)

// $btn-danger-color:               #fff
// $btn-danger-bg:                  $brand-danger
// $btn-danger-border:              darken($btn-danger-bg, 5%)

// $btn-link-disabled-color:        $gray-light

// Allows for customizing button radius independently from global border radius
// $btn-border-radius-base:         $border-radius-base
// $btn-border-radius-large:        $border-radius-large
// $btn-border-radius-small:        $border-radius-small


//== Forms
//
//##

//** `<input>` background color
// $input-bg:                       #fff
//** `<input disabled>` background color
// $input-bg-disabled:              $gray-lighter

//** Text color for `<input>`s
// $input-color:                    $gray
//** `<input>` border color
// $input-border:                   #ccc

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
// $input-border-radius:            $border-radius-base
//** Large `.form-control` border radius
// $input-border-radius-large:      $border-radius-large
//** Small `.form-control` border radius
// $input-border-radius-small:      $border-radius-small

//** Border color for inputs on focus
// $input-border-focus:             #66afe9

//** Placeholder text color
// $input-color-placeholder:        #999

//** Default `.form-control` height
// $input-height-base:              ($line-height-computed + ($padding-base-vertical * 2) + 2)
//** Large `.form-control` height
// $input-height-large:             (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2)
//** Small `.form-control` height
// $input-height-small:             (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2)

//** `.form-group` margin
// $form-group-margin-bottom:       15px

// $legend-color:                   $gray-dark
// $legend-border-color:            #e5e5e5

//** Background color for textual input addons
// $input-group-addon-bg:           $gray-lighter
//** Border color for textual input addons
// $input-group-addon-border-color: $input-border

//** Disabled cursor for form controls and buttons.
// $cursor-disabled:                not-allowed


//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
// $dropdown-bg:                    #fff
//** Dropdown menu `border-color`.
// $dropdown-border:                rgba(0,0,0,.15)
//** Dropdown menu `border-color` **for IE8**.
// $dropdown-fallback-border:       #ccc
//** Divider color for between dropdown items.
// $dropdown-divider-bg:            #e5e5e5

//** Dropdown link text color.
// $dropdown-link-color:            $gray-dark
//** Hover color for dropdown links.
// $dropdown-link-hover-color:      darken($gray-dark, 5%)
//** Hover background for dropdown links.
// $dropdown-link-hover-bg:         #f5f5f5

//** Active dropdown menu item text color.
// $dropdown-link-active-color:     $component-active-color
//** Active dropdown menu item background color.
// $dropdown-link-active-bg:        $component-active-bg

//** Disabled dropdown menu item background color.
// $dropdown-link-disabled-color:   $gray-light

//** Text color for headers within dropdown menus.
// $dropdown-header-color:          $gray-light

//** Deprecated `$dropdown-caret-color` as of v3.1.0
// $dropdown-caret-color:           #000


//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

// $zindex-navbar:            1000
// $zindex-dropdown:          1000
// $zindex-popover:           1060
// $zindex-tooltip:           1070
// $zindex-navbar-fixed:      1030
// $zindex-modal-background:  1040
// $zindex-modal:             1050


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
// $screen-xs:                  480px
//** Deprecated `$screen-xs-min` as of v3.2.0
// $screen-xs-min:              $screen-xs
//** Deprecated `$screen-phone` as of v3.0.1
// $screen-phone:               $screen-xs-min

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
// $screen-sm:                  768px
// $screen-sm-min:              $screen-sm
//** Deprecated `$screen-tablet` as of v3.0.1
// $screen-tablet:              $screen-sm-min

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
// $screen-md:                  992px
// $screen-md-min:              $screen-md
//** Deprecated `$screen-desktop` as of v3.0.1
// $screen-desktop:             $screen-md-min

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
// $screen-lg:                  1200px
// $screen-lg-min:              $screen-lg
//** Deprecated `$screen-lg-desktop` as of v3.0.1
// $screen-lg-desktop:          $screen-lg-min

// So media queries don't overlap when required, provide a maximum
// $screen-xs-max:              ($screen-sm-min - 1)
// $screen-sm-max:              ($screen-md-min - 1)
// $screen-md-max:              ($screen-lg-min - 1)


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
// $grid-columns:              12
//** Padding between columns. Gets divided in half for the left and right.
// $grid-gutter-width:         30px
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
// $grid-float-breakpoint:     $screen-sm-min
//** Point at which the navbar begins collapsing.
// $grid-float-breakpoint-max: ($grid-float-breakpoint - 1)


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
// $container-tablet:             (720px + $grid-gutter-width)
//** For `$screen-sm-min` and up.
// $container-sm:                 $container-tablet

// Medium screen / desktop
// $container-desktop:            (940px + $grid-gutter-width)
//** For `$screen-md-min` and up.
// $container-md:                 $container-desktop

// Large screen / wide desktop
// $container-large-desktop:      (1140px + $grid-gutter-width)
//** For `$screen-lg-min` and up.
// $container-lg:                 $container-large-desktop


//== Navbar
//
//##

// Basics of a navbar
// $navbar-height:                    50px
// $navbar-margin-bottom:             $line-height-computed
// $navbar-border-radius:             $border-radius-base
// $navbar-padding-horizontal:        floor(($grid-gutter-width / 2))
// $navbar-padding-vertical:          (($navbar-height - $line-height-computed) / 2)
// $navbar-collapse-max-height:       340px

// $navbar-default-color:             #777
// $navbar-default-bg:                #f8f8f8
// $navbar-default-border:            darken($navbar-default-bg, 6.5%)

// Navbar links
// $navbar-default-link-color:                #777
// $navbar-default-link-hover-color:          #333
// $navbar-default-link-hover-bg:             transparent
// $navbar-default-link-active-color:         #555
// $navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%)
// $navbar-default-link-disabled-color:       #ccc
// $navbar-default-link-disabled-bg:          transparent

// Navbar brand label
// $navbar-default-brand-color:               $navbar-default-link-color
// $navbar-default-brand-hover-color:         darken($navbar-default-brand-color, 10%)
// $navbar-default-brand-hover-bg:            transparent

// Navbar toggle
// $navbar-default-toggle-hover-bg:           #ddd
// $navbar-default-toggle-icon-bar-bg:        #888
// $navbar-default-toggle-border-color:       #ddd


//=== Inverted navbar
// Reset inverted navbar basics
// $navbar-inverse-color:                      lighten($gray-light, 15%)
// $navbar-inverse-bg:                         #222
// $navbar-inverse-border:                     darken($navbar-inverse-bg, 10%)

// Inverted navbar links
// $navbar-inverse-link-color:                 lighten($gray-light, 15%)
// $navbar-inverse-link-hover-color:           #fff
// $navbar-inverse-link-hover-bg:              transparent
// $navbar-inverse-link-active-color:          $navbar-inverse-link-hover-color
// $navbar-inverse-link-active-bg:             darken($navbar-inverse-bg, 10%)
// $navbar-inverse-link-disabled-color:        #444
// $navbar-inverse-link-disabled-bg:           transparent

// Inverted navbar brand label
// $navbar-inverse-brand-color:                $navbar-inverse-link-color
// $navbar-inverse-brand-hover-color:          #fff
// $navbar-inverse-brand-hover-bg:             transparent

// Inverted navbar toggle
// $navbar-inverse-toggle-hover-bg:            #333
// $navbar-inverse-toggle-icon-bar-bg:         #fff
// $navbar-inverse-toggle-border-color:        #333


//== Navs
//
//##

//=== Shared nav styles
// $nav-link-padding:                          10px 15px
// $nav-link-hover-bg:                         $gray-lighter

// $nav-disabled-link-color:                   $gray-light
// $nav-disabled-link-hover-color:             $gray-light

//== Tabs
// $nav-tabs-border-color:                     #ddd

// $nav-tabs-link-hover-border-color:          $gray-lighter

// $nav-tabs-active-link-hover-bg:             $body-bg
// $nav-tabs-active-link-hover-color:          $gray
// $nav-tabs-active-link-hover-border-color:   #ddd

// $nav-tabs-justified-link-border-color:            #ddd
// $nav-tabs-justified-active-link-border-color:     $body-bg

//== Pills
// $nav-pills-border-radius:                   $border-radius-base
// $nav-pills-active-link-hover-bg:            $component-active-bg
// $nav-pills-active-link-hover-color:         $component-active-color


//== Pagination
//
//##

// $pagination-color:                     $link-color
// $pagination-bg:                        #fff
// $pagination-border:                    #ddd

// $pagination-hover-color:               $link-hover-color
// $pagination-hover-bg:                  $gray-lighter
// $pagination-hover-border:              #ddd

// $pagination-active-color:              #fff
// $pagination-active-bg:                 $brand-primary
// $pagination-active-border:             $brand-primary

// $pagination-disabled-color:            $gray-light
// $pagination-disabled-bg:               #fff
// $pagination-disabled-border:           #ddd


//== Pager
//
//##

// $pager-bg:                             $pagination-bg
// $pager-border:                         $pagination-border
// $pager-border-radius:                  15px

// $pager-hover-bg:                       $pagination-hover-bg

// $pager-active-bg:                      $pagination-active-bg
// $pager-active-color:                   $pagination-active-color

// $pager-disabled-color:                 $pagination-disabled-color


//== Jumbotron
//
//##

// $jumbotron-padding:              30px
// $jumbotron-color:                inherit
// $jumbotron-bg:                   $gray-lighter
// $jumbotron-heading-color:        inherit
// $jumbotron-font-size:            ceil(($font-size-base * 1.5))
// $jumbotron-heading-font-size:    ceil(($font-size-base * 4.5))


//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

// $state-success-text:             #3c763d
// $state-success-bg:               #dff0d8
// $state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%)

// $state-info-text:                #31708f
// $state-info-bg:                  #d9edf7
// $state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%)

// $state-warning-text:             #8a6d3b
// $state-warning-bg:               #fcf8e3
// $state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 5%)

// $state-danger-text:              #a94442
// $state-danger-bg:                #f2dede
// $state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%)


//== Tooltips
//
//##

//** Tooltip max width
// $tooltip-max-width:           200px
//** Tooltip text color
// $tooltip-color:               #fff
//** Tooltip background color
// $tooltip-bg:                  #000
// $tooltip-opacity:             .9

//** Tooltip arrow width
// $tooltip-arrow-width:         5px
//** Tooltip arrow color
// $tooltip-arrow-color:         $tooltip-bg


//== Popovers
//
//##

//** Popover body background color
// $popover-bg:                          #fff
//** Popover maximum width
// $popover-max-width:                   276px
//** Popover border color
// $popover-border-color:                rgba(0,0,0,.2)
//** Popover fallback border color
// $popover-fallback-border-color:       #ccc

//** Popover title background color
// $popover-title-bg:                    darken($popover-bg, 3%)

//** Popover arrow width
// $popover-arrow-width:                 10px
//** Popover arrow color
// $popover-arrow-color:                 $popover-bg

//** Popover outer arrow width
// $popover-arrow-outer-width:           ($popover-arrow-width + 1)
//** Popover outer arrow color
// $popover-arrow-outer-color:           fade_in($popover-border-color, 0.05)
//** Popover outer arrow fallback color
// $popover-arrow-outer-fallback-color:  darken($popover-fallback-border-color, 20%)


//== Labels
//
//##

//** Default label background color
// $label-default-bg:            $gray-light
//** Primary label background color
// $label-primary-bg:            $brand-primary
//** Success label background color
// $label-success-bg:            $brand-success
//** Info label background color
// $label-info-bg:               $brand-info
//** Warning label background color
// $label-warning-bg:            $brand-warning
//** Danger label background color
// $label-danger-bg:             $brand-danger

//** Default label text color
// $label-color:                 #fff
//** Default text color of a linked label
// $label-link-hover-color:      #fff


//== Modals
//
//##

//** Padding applied to the modal body
// $modal-inner-padding:         15px

//** Padding applied to the modal title
// $modal-title-padding:         15px
//** Modal title line-height
// $modal-title-line-height:     $line-height-base

//** Background color of modal content area
// $modal-content-bg:                             #fff
//** Modal content border color
$modal-content-border-color:                   #e5e5e5;
//** Modal content border color **for IE8**
$modal-content-fallback-border-color:          #e5e5e5;

//** Modal backdrop background color
$modal-backdrop-bg:           #fff;
//** Modal backdrop opacity
$modal-backdrop-opacity:      .94;
//** Modal header border color
// $modal-header-border-color:   #e5e5e5
//** Modal footer border color
// $modal-footer-border-color:   $modal-header-border-color

// $modal-lg:                    900px
$modal-md:                    690px;
// $modal-sm:                    300px


//== Alerts
//
//## Define alert colors, border radius, and padding.

// $alert-padding:               15px
// $alert-border-radius:         $border-radius-base
// $alert-link-font-weight:      bold

// $alert-success-bg:            $state-success-bg
// $alert-success-text:          $state-success-text
// $alert-success-border:        $state-success-border

// $alert-info-bg:               $state-info-bg
// $alert-info-text:             $state-info-text
// $alert-info-border:           $state-info-border

// $alert-warning-bg:            $state-warning-bg
// $alert-warning-text:          $state-warning-text
// $alert-warning-border:        $state-warning-border

// $alert-danger-bg:             $state-danger-bg
// $alert-danger-text:           $state-danger-text
// $alert-danger-border:         $state-danger-border


//== Progress bars
//
//##

//** Background color of the whole progress component
// $progress-bg:                 #f5f5f5
//** Progress bar text color
// $progress-bar-color:          #fff
//** Variable for setting rounded corners on progress bar.
// $progress-border-radius:      $border-radius-base

//** Default progress bar color
// $progress-bar-bg:             $brand-primary
//** Success progress bar color
// $progress-bar-success-bg:     $brand-success
//** Warning progress bar color
// $progress-bar-warning-bg:     $brand-warning
//** Danger progress bar color
// $progress-bar-danger-bg:      $brand-danger
//** Info progress bar color
// $progress-bar-info-bg:        $brand-info


//== List group
//
//##

//** Background color on `.list-group-item`
// $list-group-bg:                 #fff
//** `.list-group-item` border color
// $list-group-border:             #ddd
//** List group border radius
// $list-group-border-radius:      $border-radius-base

//** Background color of single list items on hover
// $list-group-hover-bg:           #f5f5f5
//** Text color of active list items
// $list-group-active-color:       $component-active-color
//** Background color of active list items
// $list-group-active-bg:          $component-active-bg
//** Border color of active list elements
// $list-group-active-border:      $list-group-active-bg
//** Text color for content within active list items
// $list-group-active-text-color:  lighten($list-group-active-bg, 40%)

//** Text color of disabled list items
// $list-group-disabled-color:      $gray-light
//** Background color of disabled list items
// $list-group-disabled-bg:         $gray-lighter
//** Text color for content within disabled list items
// $list-group-disabled-text-color: $list-group-disabled-color

// $list-group-link-color:         #555
// $list-group-link-hover-color:   $list-group-link-color
// $list-group-link-heading-color: #333


//== Panels
//
//##

// $panel-bg:                    #fff
// $panel-body-padding:          15px
// $panel-heading-padding:       10px 15px
// $panel-footer-padding:        $panel-heading-padding
// $panel-border-radius:         $border-radius-base

//** Border color for elements within panels
// $panel-inner-border:          #ddd
// $panel-footer-bg:             #f5f5f5

// $panel-default-text:          $gray-dark
// $panel-default-border:        #ddd
// $panel-default-heading-bg:    #f5f5f5

// $panel-primary-text:          #fff
// $panel-primary-border:        $brand-primary
// $panel-primary-heading-bg:    $brand-primary

// $panel-success-text:          $state-success-text
// $panel-success-border:        $state-success-border
// $panel-success-heading-bg:    $state-success-bg

// $panel-info-text:             $state-info-text
// $panel-info-border:           $state-info-border
// $panel-info-heading-bg:       $state-info-bg

// $panel-warning-text:          $state-warning-text
// $panel-warning-border:        $state-warning-border
// $panel-warning-heading-bg:    $state-warning-bg

// $panel-danger-text:           $state-danger-text
// $panel-danger-border:         $state-danger-border
// $panel-danger-heading-bg:     $state-danger-bg


//== Thumbnails
//
//##

//** Padding around the thumbnail image
// $thumbnail-padding:           4px
//** Thumbnail background color
// $thumbnail-bg:                $body-bg
//** Thumbnail border color
// $thumbnail-border:            #ddd
//** Thumbnail border radius
// $thumbnail-border-radius:     $border-radius-base

//** Custom text color for thumbnail captions
// $thumbnail-caption-color:     $text-color
//** Padding around the thumbnail caption
// $thumbnail-caption-padding:   9px


//== Wells
//
//##

// $well-bg:                     #f5f5f5
// $well-border:                 darken($well-bg, 7%)


//== Badges
//
//##

// $badge-color:                 #fff
//** Linked badge text color on hover
// $badge-link-hover-color:      #fff
// $badge-bg:                    $gray-light

//** Badge text color in active nav link
// $badge-active-color:          $link-color
//** Badge background color in active nav link
// $badge-active-bg:             #fff

// $badge-font-weight:           bold
// $badge-line-height:           1
// $badge-border-radius:         10px


//== Breadcrumbs
//
//##

// $breadcrumb-padding-vertical:   8px
// $breadcrumb-padding-horizontal: 15px
//** Breadcrumb background color
// $breadcrumb-bg:                 #f5f5f5
//** Breadcrumb text color
// $breadcrumb-color:              #ccc
//** Text color of current page in the breadcrumb
// $breadcrumb-active-color:       $gray-light
//** Textual separator for between breadcrumb elements
// $breadcrumb-separator:          "/"


//== Carousel
//
//##

// $carousel-text-shadow:                        0 1px 2px rgba(0,0,0,.6)

// $carousel-control-color:                      #fff
// $carousel-control-width:                      15%
// $carousel-control-opacity:                    .5
// $carousel-control-font-size:                  20px

// $carousel-indicator-active-bg:                #fff
// $carousel-indicator-border-color:             #fff

// $carousel-caption-color:                      #fff


//== Close
//
//##

// $close-font-weight:           bold
// $close-color:                 #000
// $close-text-shadow:           0 1px 0 #fff


//== Code
//
//##

// $code-color:                  #c7254e
// $code-bg:                     #f9f2f4

// $kbd-color:                   #fff
// $kbd-bg:                      #333

// $pre-bg:                      #f5f5f5
// $pre-color:                   $gray-dark
// $pre-border-color:            #ccc
// $pre-scrollable-max-height:   340px


//== Type
//
//##

//** Horizontal offset for forms and lists.
// $component-offset-horizontal: 180px
//** Text muted color
// $text-muted:                  $gray-light
//** Abbreviations and acronyms border color
// $abbr-border-color:           $gray-light
//** Headings small color
// $headings-small-color:        $gray-light
//** Blockquote small color
// $blockquote-small-color:      $gray-light
//** Blockquote font size
// $blockquote-font-size:        ($font-size-base * 1.25)
//** Blockquote border color
// $blockquote-border-color:     $gray-lighter
//** Page header border color
// $page-header-border-color:    $gray-lighter
//** Width of horizontal description list titles
// $dl-horizontal-offset:        $component-offset-horizontal
//** Point at which .dl-horizontal becomes horizontal
// $dl-horizontal-breakpoint:    $grid-float-breakpoint
//** Horizontal line color.
// $hr-border:                   $gray-lighter
@mixin breaktext(){
	white-space: pre-wrap;       /* css-3 */
	white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
	white-space: -pre-wrap;      /* Opera 4-6 */
	white-space: -o-pre-wrap;    /* Opera 7 */
	word-wrap: break-word;  
}

@mixin ellipsis(){
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

@mixin border-radius($radius){
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
          border-radius: $radius;
          overflow: hidden;
}

@mixin transition($transition, $transition2:false, $transition3:false ){
    @if( $transition2 or $transition3  ) {  

        @if( $transition2 and $transition3 == false  ) {  
            transition: $transition, $transition2;
            -webkit-transition: $transition, $transition2;
            -moz-transition: $transition, $transition2;
            -o-transition: $transition, $transition2;
        }

        @if( $transition2 and $transition3  ) {  
            transition: $transition, $transition2, $transition3; 
            -webkit-transition: $transition, $transition2, $transition3;
            -moz-transition: $transition, $transition2, $transition3;
            -o-transition: $transition, $transition2, $transition3;
        }

    } @else {        
        transition: $transition;
        -webkit-transition: $transition;
        -moz-transition: $transition;
        -o-transition: $transition;
    }
}

@mixin box-shadow ($boxshadow){
	box-shadow: $boxshadow;
	-webkit-box-shadow: $boxshadow;
	-moz-box-shadow: $boxshadow;
	-o-box-shadow: $boxshadow;
}

@mixin transform($transform) {
	-moz-transform: $transform;
	-ms-transform: $transform;
	-o-transform: $transform;
	-webkit-transform: $transform;
	transform: $transform;
    -webkit-backface-visibility: hidden;
}

@mixin extend-icomoon (){
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


@mixin color-variant( $variable:false, $single:false ) {
    $listColour: $darkish-purple $turtle-green $slate  $brownish-orange $lipstick $leaf $dark-orange $teal $london-hue $dark-cerulean;
    $count: 1;

    @each $colour in $listColour {
        @if( $variable ) {  
            @if( $single ) {  
                .is-#{$variable}-colour-#{$count} & {    
                    color: $colour;
                }
            } @else {
                 [class^="is-colour-"] .is-#{$variable}-colour-#{$count} & {    
                    color: $colour;
                }
            }

        } @else {
            .is-colour-#{$count} & {    
                color: $colour;
            }
        }

        $count : $count + 1;
    }
}

@mixin border-variant( $variable:false ) {
    $listColour: $darkish-purple $turtle-green $slate  $brownish-orange $lipstick $leaf $dark-orange $teal $london-hue $dark-cerulean;
    $count: 1;

    @each $colour in $listColour {
        @if( $variable ) {  
            [class^="is-colour-"] .is-#{$variable}-colour-#{$count} & {    
                border-color: $colour;
            }
        } @else {
            .is-colour-#{$count} & {    
                border-color: $colour;
            }
        }

        $count : $count + 1;
    }
}

@mixin background-variant( $opacity:false, $variable:false ) {
    $listColour: $darkish-purple $turtle-green $slate  $brownish-orange $lipstick $leaf $dark-orange $teal $london-hue $dark-cerulean;
    $count: 1;

    @each $colour in $listColour {
        @if( $variable ) {  
            [class^="is-colour-"] .is-#{$variable}-colour-#{$count} & {    
                @if( $opacity ) {  
                    background-color: rgba($colour, $opacity);
                } @else {
                    background-color: $colour;
                }
            }
        } @else {
            .is-colour-#{$count} & {  
                 @if( $opacity ) {  
                    background-color: rgba($colour, $opacity);
                } @else {
                    background-color: $colour;
                }
            }
        }

        $count : $count + 1;
    }
}

@mixin bghover-variant( $opacity:false, $variable:false ) {
    $listColour: $darkish-purple $turtle-green $slate  $brownish-orange $lipstick $leaf $dark-orange $teal $london-hue $dark-cerulean;
    $count: 1;

    @each $colour in $listColour {
        @if( $variable ) {  
            [class^="is-colour-"] .is-#{$variable}-colour-#{$count} & {    
                background-color: $colour;
                &:hover,
                &:focus,
                &:active {
                    background-color:  lighten($colour, $opacity);
                }
            }
        } @else {
            .is-colour-#{$count} & {    
                background-color: $colour;
                &:hover,
                &:focus,
                &:active {
                    background-color:  lighten($colour, $opacity);
                }
            }
        }
        
        $count : $count + 1;
    }
}



@mixin background-image( $opacity:false, $variable:false ) {
    $listColour: $darkish-purple $turtle-green $slate  $brownish-orange $lipstick $leaf $dark-orange $teal $london-hue $dark-cerulean;
    $count: 1;

    @each $colour in $listColour {
        @if( $variable ) {  
            [class^="is-colour-"] .is-#{$variable}-colour-#{$count} & {    
                background-image: linear-gradient(to right, $colour, $colour $opacity, transparent $opacity, transparent);
            }
        } @else {
            .is-colour-#{$count} & {    
                background-image: linear-gradient(to right, $colour, $colour $opacity, transparent $opacity, transparent);
            }
        }
        
        $count : $count + 1;
    }
}




// Media Query break points
@mixin break-xs ( $isMin:false ) {
    
    @if( $isMin == true ) {
        @media only screen and (min-width: $screen-xs + 1) {
            @content;
        }
    } @else {

        @media only screen and (max-width: $screen-xs-min) {
            @content;
        }
    }
}

@mixin break-ss( $isMin:false ) {
    
    @if( $isMin == true ) {
        @media only screen and (min-width: 600px) {
            @content;
        }
    } @else {

        @media only screen and (max-width: 599px) {
            @content;
        }
    }
}

@mixin break-sm( $isMin:false ) {
    
    @if( $isMin == true ) {
        @media only screen and (min-width: $screen-sm) {
            @content;
        }
    } @else {

        @media only screen and (max-width: $screen-xs-max) {
            @content;
        }
    }
}

@mixin break-md( $isMin:false ) {
    
    @if( $isMin == true ) {
        @media only screen and (min-width: $screen-md) {
            @content;
        }
    } @else {

        @media only screen and (max-width: $screen-sm-max) {
            @content;
        }
    }
}

@mixin break-lg( $isMin:false ) {
    @if( $isMin == true ) {
        @media only screen and (min-width: $screen-lg) {
            @content;
        }
    } @else {

        @media only screen and (max-width: $screen-md-max) {
            @content;
        }
    }
}

// targeting browser
@mixin is-firefox() {
    @-moz-document url-prefix() { 
        @content;
    }
}

@mixin is-chrome(){
    @media screen and (-webkit-min-device-pixel-ratio:0) {
        @content;
    }
}

@mixin is-ie(){
    /* IE10+ */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

@mixin is-safari(){
    /* Safari 6.1-10.0 (10.1 is the latest version of Safari at this time) */
    @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) { 
        @media {
            @content;
        }
    }
}

// rotating screen
@mixin is-portrait() {
    @media screen and (orientation:portrait) {
       @content;
    }
}

@mixin is-landscape() {
    @media screen and (orientation:landscape) {
       @content;
    }
}

/*######################################################
  COMPONENTS SKIN
#######################################################*/
/*------------------------------------------------------
	badge
------------------------------------------------------*/
.badge {
	background-color: #fff;
    color:$gray;

	.is-warning & {
		color: $brand-warning;
	}

	.is-danger & {
		color: $brand-danger;
	}
}

/*------------------------------------------------------
	buttons
------------------------------------------------------*/
.btn-addnew {
    color: $brand-primary;

    &:hover,
    &:active,
    &.focus,
	&:focus,
	&.active {
		color: darken($brand-primary, 10%);
	}
}


.btn-borderless {
    color: $brand-primary;
}


.btn-default {

	&.inverse {
		color: $link-color;
        border-color: $link-color;
		
		&:hover,
		&:focus,
		&:active {
			color: $link-color !important;
		}
	}
}

.btn-primary {
	&.inverse {
		color: $brand-primary;
	}
}


/*------------------------------------------------------
	checkbox
------------------------------------------------------*/
.checkbox label:before {
	border-color: $border-color;
}

.checkbox.is-bgactive {
    > input:checked ~ label:before{
        background-color:$color-primary;       
        border-color: $color-primary;            
    }

	> input:checked ~ label{
        color:$color-primary;
    }
}

/*------------------------------------------------------
  dropdown
------------------------------------------------------*/ 
.dropdown-menu {
  border-color: $border-color;
  li {
        a {
            color: $color-primary;
        }
    }
 }

/*------------------------------------------------------
  file-upload
------------------------------------------------------*/ 
.custom-file-upload {
    &:hover,
    &:active,
    &.focus,
	&:focus,
	&.active {
		color: $color-primary;
	}

    border-color: $border-color;
    color: $color-primary;
}


/*------------------------------------------------------
  form
------------------------------------------------------*/
.form-control {
	border-color: $border-color;

	&:focus {
		border-color: $brand-primary;
	}
}

.form-field-overlay {
	background-color: $color-secondary;
	border-color: $brand-primary;
	color: $gray-light;

	&:focus,
	&:active {
		border-color: $brand-primary;
	}
}

.form-message {
    &.error {
        color: $brand-danger;
    }
}

.form-control { 
    &:disabled,
    &[disabled="disabled"] {
        background-color: lighten($gray-lighter, 30%);
        border-color: $border-color;
        color: $gray-lighter;
        cursor: not-allowed;
    }      
}

.is-datepicker {
    &:before {
        color: $color-primary;
    }
}

label {
    .modal &, .details-view & {
        color: $gray-light;
    }
}

.form-item-phone{
    .input-group-addon{
        color: $gray;
    }
}

.input-group-addon {
        background-color: lighten($gray-lighter, 30%);
        border-color: $border-color;
    }

/*------------------------------------------------------
  list group
------------------------------------------------------*/
.list-group {
	border-color: $border-color;
}

.list-group-item {
	border-color: $border-color;
}


/*------------------------------------------------------
	modal
------------------------------------------------------*/
.modal-content	.close {
	color: #ffffff;
}


/*------------------------------------------------------
	navigation
------------------------------------------------------*/
.menu-toggle {
	color: $brand-primary;
}

#mainNavigation {
    background-color: $gray-dark;
}
.top-menu-main{
	@include break-sm{
		background-color: lighten($color-primary, 100%);
	}
}
.top-menu-nav {
    > ul {
        > li {
            a {
                color: $color-primary;

	            @include break-sm{
		            color: $gray-lighter;
	            }
            }

            > a {
                color: $gray-lighter;

            }

            .dropdown-toggle {
                color: $color-primary;

	            @include break-sm{
		            color: $gray-lighter;
	            }
            }
        }
    }
}



/*------------------------------------------------------
	radio
------------------------------------------------------*/
.radio {
	input[type=radio]:checked ~ .icon-radio:after {
		background-color: $brand-primary;
	}

	.icon-radio:before {
		.error & {
			border-color: $brand-danger;
		}		
	}
}

.radiolistgorup {
	border-color: $border-color;
}

.radlsitm-col {
	border-bottom-color: $border-color;
}

/*------------------------------------------------------
	select
------------------------------------------------------*/
select {
    border-color: $border-color;
    color:  $brand-primary;
    
    &:disabled,
    &[disabled="disabled"] {
        color: $gray-lighter;
    }  
}

.select-option {    
    &:before {
        color: $brand-primary;
    }

}

.bootstrap-select {

	> .dropdown-toggle {
		border-color: $border-color;

		&:focus {
			border-color: $brand-primary !important;
		}

		// Error display
		.has-error &,
		.error & {
			border-color: $brand-danger;
		}
	}	

	&.open > .dropdown-toggle {
		border-color: $brand-primary !important;
	}

	&.btn-group .dropdown-toggle .caret {
		color: $brand-primary;
	}

	&.show-menu-arrow {
		.dropdown-toggle :before {
			border-bottom-color: $border-color;			
		}

		&.dropup .dropdown-toggle:before {
			border-top-color: $border-color;			
		}
	}
}


/*------------------------------------------------------
  tab
------------------------------------------------------*/
.nav-tabs { 
	> li {
		> a {
			color: $gray-lighter;
		}
	}
}
.invoicestabwrap-navlinktab{
	color: $gray-lighter;

	&:hover, &:focus, &:active{
		color: $gray-lighter;
	}

	&.active{
		color: $gray-base;
	}
}
.returnstabwrap-navlinktab{
	color: $gray-lighter;

	&:hover, &:focus, &:active{
		color: $gray-lighter;
	}

	&.active{
		color: $gray-base;
	}
}
.tabdrop-btn {
	border-bottom-color: $border-color;

	.icon-chevron-dropdown {
		color: $brand-primary;
	}
	&:hover, &:focus, &:active{
		border-bottom-color: $border-color;
	}
	&:hover{
		@include break-sm{
			border-bottom-color: $border-color;
		}
	}

	@include break-sm{
		border-bottom-color: $border-color !important;
	}
}

.tabdrop-nav {
	@include break-sm {
		.tab-dropdown-sm & {
			> a {
				color: $brand-primary;

				&:hover,
				&:active,
				&:focus {
				color: $brand-primary;
				}
			}

			&.active > a {
				color: $gray-lighter;

				&:hover,
				&:active,
				&:focus {
					color: $gray-lighter;
				}
			}
		}
	}
}

li.uib-tab {
	&.is-uibtab-warning {
		em {
			background-color: $brand-danger;
		}
	} 

	&.is-uibtab-noticed {
		em {
			background-color: $brand-warning;
		}
	} 
}


/*------------------------------------------------------
	table
------------------------------------------------------*/
.table-wrapper {
    th {
        border-color: $border-color;
        color: $gray-lighter;
    }

    td {
        .btn-default {
            color: $brand-primary;
        }
    }

    tr {

        @include box-shadow(inset 0px 1px 0px 0px $border-color);

        @include is-ie() {
            @include box-shadow(none !important);
            border-top: 1px solid $border-color;
        }

        &.is-selected {
            @include box-shadow(inset 0px 0px 0px 1px $brand-primary);
            background-color: $color-secondary;

            @include is-ie() {
                @include box-shadow(none !important);
                border: 1px solid $brand-primary !important;
            }
        }

        &.is-show-detail {
            @include break-sm {
                border-top-color: $border-color;
            }

            &:last-child {
                @include break-sm {
                    border-bottom-color: $border-color;
                }
            }
        }

        &.is-row-danger {
            > td.td-balance > span.ng-binding {
                padding: 2px;
                background-color: $brand-danger;
            }
        }

        &.is-row-warning { 
            > td.td-balance > span.ng-binding {
                padding: 2px; 
                background-color: $brand-warning;
            }
        }

        &:last-child {
            td {
                border-bottom-color: $border-color;
            }
        }
    }

    .btn-tick {
        color: $brand-primary;
    }

    .is-warning:before {
        color: $brand-warning;
    }

    .is-danger:before {
        color: $brand-danger;
    }

    .tblwrap-search-btn {
        color: $brand-primary;
    }
}


.table-pagination {

    .pagination {
        li {          

            &.is-selected {
                a {
                    background: $color-primary;
                }
            }
        }
    }
}

.th-sort {
    &.th-sort-active-asc {
        span {
            color: $gray-base;
        }
    }

    &.th-sort-active-desc {
        span {
            color: $gray-base;
        }
    }
}


.td-tick {
    span {
        border: 2px solid $border-color;

        .is-selected & {
            background-color: $brand-primary;
            border-color: $brand-primary;
        }
    }
}

.tblwrap-head-item {
    
    .dropdown-toggle {
        color: $brand-primary;

        &:focus,
        &:active,
        &:hover,
        .open > & {
            color: $brand-primary !important;
        }       
    }

    .btn-md.btn-borderless {
        padding: 8px 35px 16px 35px;
    }
}

.tblwrap-label-ext {
    color: $brand-primary;
}

.tblwrap-select.bootstrap-select {
    > .dropdown-toggle {
        .filter-option {
            color: $brand-primary;
        }
    }    
}

.tblwrap-dropdown {
    .dropdown-toggle,
    .btn-default:active,
    .btn-default.active,
    &.open > .btn-default.dropdown-toggle {
        color: $brand-primary;
    }
}


.tblwrap-search-input {
    border: 1px solid $border-color;
    color: $gray;
}

.tblwrap-overlay {
    background-color: $brand-primary;
}

.tblwrap-ovr-links {
    ul.dropdown-menu > li >  a {   
		color: lighten($gray-base, 100%);

        &:hover,
        &:focus,
        &:active {
            color: lighten($brand-primary, 30%);

            @include break-md {
                color: $gray;
                background-color: lighten($gray, 60%);
            }
        }

        @include break-md {
            color: $brand-primary;
        }
    }    
}

.tblwrap-ovr-payselected {
    color: $brand-primary;
}

.table-grid {
    .nav-tabs {
        > li {
            &.active {
                a {
                    border-bottom-color: $color-primary !important;
                }
            }
        }
    }

    .table-head-title{
        border-bottom-color: $border-color;
    }
}

.remove-item {
    a {
        color: $border-color;

	    &.papainvcs-viewdetail{
		    color: $color-primary;
	    }
    }

}

.tblwrap-ovr-ellipses{
	background: rgba(255, 255, 255, .2);

	&:focus,
	&:active,
	&:hover {
		background: rgba(255, 255, 255, .2) !important;
	}

	.icon-ellipses {
		color: lighten($gray-base, 100%);
	}
}
.tblwrap-advancedsearchbtn {
	border-color: $border-color;
}


/*------------------------------------------------------
	panel
------------------------------------------------------*/
.invbal-icon-exclamation {
	color: $brand-danger;
}
/*######################################################
	LAYOUT
#######################################################*/
/*------------------------------------------------------
	account message
------------------------------------------------------*/
.account-message {
	background-color: $color-quaternary;
}

/*------------------------------------------------------
	content
------------------------------------------------------*/
.prpymdl-left {
	border-right-color: $border-color;
}

.drpcnt-btn {
	color: $brand-primary;

	&:hover,
    &:active,
    &.focus,
	&:focus,
	&.active {
		color: $brand-primary;
	}
}

/*------------------------------------------------------
	header
------------------------------------------------------*/
.top-menu-search {
	border-color: $border-color;
	.icon-search {
		color: $color-primary;
	}
}
.top-menu-main {

	&.is-open{
		@include break-sm{
			box-shadow: 0 0 15px lighten($gray-base, 70%);
		}
	}
}
/*------------------------------------------------------
	masthead
------------------------------------------------------*/
.mstd-actions-dropdown {
	.dropdown-toggle,
	.btn-default:active,
	.btn-default.active, 
	&.open > .btn-default.dropdown-toggle {
		border-color: $brand-primary;
		color: $brand-primary;

		&:hover,
		&:focus,
		&.focus {
			border-color: $brand-primary;
			color: $brand-primary;
		}
	}
}

.mstd-link { 
    color: $color-primary;

	.is-active & {
		border-color: $brand-primary;
		color: $gray-base;
	}
}


/*------------------------------------------------------
	quick action
------------------------------------------------------*/
.quickaction {
	background-color: $color-primary;
}

.qckact-title{
	color: lighten($link-color, 30%);
}

.qckact-list > li > a {
	&:hover {
		color: lighten($link-color, 20%);
	}

	> span {
		color: darken($link-color, 10%);
	}
}

/*------------------------------------------------------
	summary boxes
------------------------------------------------------*/
.smbxs {
  &-box {
    &.is-color-danger {
      background-color: $brand-danger;
    }

    &.is-color-darkgray {
      background-color: $gray;
    }

    &.is-color-warning {
      background-color: $brand-warning;
    }

    &.is-color-autopay{
        background-color: $color-autopay;
    }

    &.is-color-lightgray {
      background-color: lighten($gray-lighter, 30%);

      a {
        color: $brand-primary;
      }
    }

    &.is-color-info {
      background-color: $color-info;
    }

    &.is-color-white {
      background-color: #fff;
    }
  }

  &-link {
    .is-color-white & {
      color: #000;
    }
  }
}



/*------------------------------------------------------
	summary charts
------------------------------------------------------*/
.smchrt-graph {
	border-right-color: $border-color; 

	.footer {
        color: $gray-lighter;
    }
}

.smchrt-border-bottom {
	border-bottom-color: $border-color;
}

.smchrt-border-right {
	border-right-color: $border-color;
}

.smchrt-exclamation {
	color: $brand-danger;
}

.smchrt-quickact {
	background-color: $brand-primary;
}


.smchrt-quickactlink {
	&:hover,
	&:active,
	&:focus {
		color: lighten($brand-primary, 10%);
	}
}

.smchrt-row-inner {
	border-color: $border-color;
}

.smchrt-balance {
	&:before {
		.is-opr & {
			border-color: $border-color;
		}
	}
}


.smchrt-col {
	@include break-sm {
		border-bottom-color: $border-color !important;
	}
}

.smchrt-valuepercentage {
    &.is-value-up {
    	&:before {
    		color: $brand-success;
    	}
    }

    &.is-value-down {
    	&:before {
    		color: $brand-danger;
    	}
    }
}

.smchrt-sm-clear {
	@include break-sm {
		border-bottom-color: $border-color !important;
	}
}

.smchrt-quickacttitle {
	color: lighten($brand-primary, 10%);
}

/*------------------------------------------------------
	summary flat
------------------------------------------------------*/
.smryflt-item {
	background-color: $gray-light;

	&.is-color-danger {
		background-color: $brand-danger;
	}

	&.is-color-darkgray {
		background-color: $gray;
	}

	&.is-color-warning {
		background-color: $brand-warning;
	}

	&.is-color-lighgray {
		background-color: lighten($gray-lighter, 30%);

	    a {
	    	color: $brand-primary;
	    }
	}
}


/*------------------------------------------------------
	Loading spinner
------------------------------------------------------*/
.loading-spinner-icon{
	color:$color-primary;

	&.icon-exclamation{
		color: $brand-danger;
	}
}
.loading-spinner-inner .loading-spinner-circle:before{
	background-color:$color-primary;
}


/*------------------------------------------------------
	Tab Drop Down
------------------------------------------------------*/
.tabdrpd-btnlink{
	color: $gray-base;
	&:hover, &:focus, &:active{
		color: $gray-base;
	}
}
.tabdrpd-link {
	.tabdrpd-list li & {
		color: $gray-lighter;
		&:hover, &:focus, &:active{
			background-color: transparent;
			color: $gray-lighter;
		}
		&.active{
			color: $gray-base;
			border-bottom-color: $color-primary !important;

			&:hover{
				color: $gray-base;
			}

			@include break-sm{
				border-bottom-color:transparent !important;
				background-color: $color-secondary;
			}
		}
	}
}
.tabdrpd-item {
	&:after,
	&:before {
		color: $gray-lighter;
	}

	&.is-tabdrpd-item-active {
		&:after {
			color: $gray-base;
		}

		&:before {
			color: $brand-primary;
		}
	}

	&:first-child{
		border-top-color: $border-color;
	}
}
.tabdrpd-icon{
	color:  $color-primary;
}


/*------------------------------------------------------
	advanced search
------------------------------------------------------*/
.advancedsearch > form{
	border-color: $border-color;
}
.advsrch-item{
	border-bottom-color: $border-color;
}
.advsrch-groupitem{
	border-bottom-color: $border-color;
}
.advsrch-select{
	.checkbox > input:checked ~ label:before {
		color: lighten($color-primary, 100%);
	}
}
.advsrch-borderleft{
	border-right-color: $border-color;
}
.advsrch-formlabel{
	color: $gray-lighter;
}
.advsrch-icon{
	color: $gray-lighter;

	.is-calendar &{
		color: $color-primary;
	}
}

/*------------------------------------------------------
	report
------------------------------------------------------*/
.report > form{
	border-color: $border-color;
}
.report-item{
	border-bottom-color: $border-color;
}
.report-groupitem{
	border-bottom-color: $border-color;
}
.report-select{
	.checkbox > input:checked ~ label:before {
		color: lighten($color-primary, 100%);
	}
}
.report-borderleft{
	border-right-color: $border-color;
}
.report-formlabel{
	color: $gray-lighter;
}
.report-icon{
	color: $gray-lighter;

	.is-calendar &{
		color: $color-primary;
	}
}

/*------------------------------------------------------
	filter search
------------------------------------------------------*/
.fltrsrch-btn-search {
	color: $brand-primary;
}
/*######################################################
  PAGES SKIN
#######################################################*/
/*------------------------------------------------------
	account profile page
------------------------------------------------------*/
.accntprof-contactitem {	
	&.is-editing {
		background-color: $color-secondary;
	}
}

.accntprof-contactitem {
	border-bottom-color: $border-color;
}

.ccntprof-contactrow {	
    border-bottom-color: $gray-light;
}

.form-wrapper{
	&.form-contactedit {
		.icon-trash {
			color: $link-color;
		}
	}
}


/*------------------------------------------------------
	confirmation page
------------------------------------------------------*/
.recdet-label {
	color: $gray-lighter;
}

.recdet-divider {    
    border-top-color: $border-color;
}

.receipt-forward {
	background-color: $color-secondary;
}

.clear-padding-right {
    padding-right: 0px;
}



/*------------------------------------------------------
	contact details
------------------------------------------------------*/
.contactdetails-list{  
    > li{        
        color: $gray-base;

        .contactdetails-icon{
            color: $gray-lighter;
        }

    }
}

/*------------------------------------------------------
	contact list
------------------------------------------------------*/
.contact-list {
	.table-grid .nav-tabs > li.active a {
		border-bottom: 1px solid $border-color !important;
	}
}

/*------------------------------------------------------
	email confirmation page
------------------------------------------------------*/
.emlconf-confirmation {
	background-color:$color-tertiary;
}

.emlconf-icocircle {
	.icon-tick {
		color: $color-tertiary;
	}
}

.emlconf-divider {
	border-top-color: $border-color;
}

.emlconf-passlevel {
	color: $gray-light;

	em {
		color: $brand-danger;

		&.is-strong {
			color: $color-tertiary;
		}
	}
}

/*------------------------------------------------------
	forgot password page
------------------------------------------------------*/
.frgtpswd-confirmation {
	background-color: $color-tertiary;
}

.frgtpswd-icocircle {
	.icon-tick {
		color: $color-tertiary;
	}
}

/*------------------------------------------------------
	invoices and payments page
------------------------------------------------------*/
.invoicestab {
    border-bottom-color: $border-color;

	ul {
		border-bottom-color: $border-color;
	}   

	span {
		background: $color-primary;
	} 

    > li {
        > a {
            color: $gray-lighter;

            &:hover,
            &:active,
            &:focus {
                color: $gray-lighter;
            }
        }

        &.active {
            > a {
                border-color: $brand-primary;
                color: $gray-base;

                &:hover,
                &:active,
                &:focus {
                    color: $gray-base;
                }
            }
        }
    }
}

.package-col-summary {
    @include break-lg (true) {
        border-left-color: $border-color;
    }

    @include break-lg {
        border-top-color: $border-color;
    }
}

.packagesumm-row {
        border-bottom-color: $border-color;
}

/*   invoice balance */
.invbal-col-overdue {
    @include break-lg (true) {
        border-left-color: $border-color;
    }

    @include break-lg {
        border-top-color: $border-color;
    }
}

.invbal-col-current {
    @include break-lg (true) {
        border-left-color: $border-color;
    }

    @include break-lg {
        border-top-color: $border-color;
    }
}

.invbal-icon-exclamation {
    color: $brand-danger;
}

.invpay-col-export-dropdown .btn {
	color: $brand-primary;

	&:hover,
	&:active,
	&:focus {
		color: $brand-primary !important;
	}
}


/*------------------------------------------------------
    invoice
-------------------------------------------------------*/
.invc-col-number {
    @include break-sm {
        border-bottom-color: $border-color;
    }
}

.invc-col-amount {
    @include break-md(true) {
        border-left-color: $border-color;
    }
}

.invc-col-actions {
    @include break-md {
        border-top-color: $border-color;
    }

    @include break-sm {
        border-bottom-color: $border-color;
    }
    
}

.invc-col-services {
    @include break-md (true) {
        border-left-color: $border-color;
    }

    @include break-md {
        border-top-color: $border-color;

    }
}





/*------------------------------------------------------
    login page
------------------------------------------------------*/
.login {   
    &-header {
        border-bottom-color: $border-color;
    }

    &-content {
        h1 {
            color: $gray;
 
        }
    }
}

/*------------------------------------------------------
	operator dashboard page
------------------------------------------------------*/
.content-wrapper {
	&-header {
		border-bottom-color: $border-color;
	}
}

.table-dashboard {
	&-subnote {
		color: $gray-light;
	}
}

/*------------------------------------------------------
	payment page
------------------------------------------------------*/
.paymnt-left {
	border-right-color: $border-color;

	@include break-sm {
		border-bottom-color: $border-color;
	}
}

.paymnt-navtabs > li {	
	> a {
		color: $gray-lighter;
	}

	&.active {
		a,
		a:hover,
		a:focus,
		a:active {	
			background-color: $color-secondary;			
			border-bottom-color: $border-secondary;
			border-left-color: $border-secondary;
			border-top-color: $border-secondary;
			color: $gray-base;


			&:before {
				border-bottom-color: $brand-primary;
				border-top-color: $brand-primary;
				border-right-color: $brand-primary;
			}
		}
	}

	@include break-sm {	
		&.active > a,
		&.active > a:hover,
		&.active > a:focus,
		&.active > a:active {
			border-right-color: $border-secondary;
			border-left-color: $border-secondary;
			border-top-color: $border-secondary;

			&:before,
			&:hover:before,			
			&:focus:before,			
			&:active:before  {					
				border-bottom-color: $brand-primary;
				border-left-color: $brand-primary;
				border-right-color: $brand-primary;
			}
		}
	}
}

[class*="paymnt-logo-"],
[class^="paymnt-logo-"] {
	.paymnt-option-logo & {
		border-color: $border-color;
	}
}

.paymnt-btn-option {
    border-color: $border-color;

    &.is-paymntbtn-selected {
        border-color: $brand-primary;
        background-color: $color-secondary;
        background-color: gainsboro;
    }
}

.paymnt-option-logo i {
	color: $brand-primary;	
}

.paymnt-section-inner {
	border-bottom-color: $border-color;
}


/*------------------------------------------------------
  part pay invoices page
------------------------------------------------------*/
.papainvcs-table {
	border-color: $border-color;

	th {
		border-color: $border-color;
	}
}


/*------------------------------------------------------
	payment page
------------------------------------------------------*/
.paymnt-subhead {
    border-bottom-color: $border-color;
}

[class*="paymnt-logo-"],
[class^="paymnt-logo-"] {
    .paymnt-option-logo & {
        background-color: lighten($gray-lighter, 35%);
    }
}


.paymnt-btnwrap {
    border-top-color: $border-color;
}

.paymnt-form {
    border-bottom-color: $border-color;
}

/*------------------------------------------------------
	payment history page
------------------------------------------------------*/
.payment-history{
	table > tbody > tr{
		&.is-selected, &.show-detail.is-selected{
			@include break-sm{
				border-color: $color-primary;
				background-color: $color-secondary;
			}
		}

		@include break-sm{
			border-color: $border-color;
			border-left-color: transparent;
			border-right-color: transparent;
		}
	}
}

/*------------------------------------------------------
	reminder strategy template page
------------------------------------------------------*/
.remstratem-templateitem {
	&.is-template-active {
		border-color: $brand-primary;
		background-color: $color-secondary;
	}
}

.remstratem-templateinner {	
	.is-template-active & {
		border-color: $border-secondary;
	}
}

.remstratem-tmpinner-content {
	h4 {
		color: $brand-primary;
	}
}

.remstratem-tmpinner-action {
	.is-template-active & {
		.icon-delete {
			color: $brand-danger;
		}
	}
}

.remstratem-label {
	color: $gray-lighter;
}


.remstratem-btnwrap {
	&:before {
		border-top-color: $border-color;
	}
}

.remstratem-title {
	color: $gray-base;
}


.remstratem-mergedfields {
	span {
		color: $link-color;
	}
}

.form-template {
	.radio label {
		color: $gray-lighter;
	}

	.form-control {
		color: $gray-base;
	}
}

/* col left */
.remstratem-col-left {
	border-bottom-color: $border-color;
	border-right-color: $border-color;
}

.remstratem-templateinner {	
	border-color: $border-color;
}

.remstratem-tmpinner-content {
	h4 {
		.is-template-active & {
			color: $gray-base;
		}
	}
}


/* col right */
.remstratem-col-right {
	border-bottom-color: $border-color;
}


/*------------------------------------------------------
	returns page
------------------------------------------------------*/
.rtrns-group {
	border-top-color:$border-color;
}

/*------------------------------------------------------
	Table with fixed action column
------------------------------------------------------*/
.fixed-col-table {
    overflow-x: scroll;
    margin-right: 22%;
}

.responive-table table {
    border-collapse: separate;
    border-spacing: 0;
    border-top: 1px solid #dfdfdf;
}

th.fixed-col {
    border-top: 2px solid #dfdfdf;
    margin-top: -1px;
    padding: 16px;
}

td.fixed-col {
    padding: 37px 10px !important;
}

.fixed-col {
    border-top: 1px solid #dfdfdf;
    width: 22%;
}
.fixed-col, .fixed-col div {
    position: absolute !important;
    right: 0 !important;
}

/*.responive-table table {
    border-collapse: separate;
    border-spacing: 0;
}*/

/*------------------------------------------------------
	print
------------------------------------------------------*/
@media print {

    a {
        display: none;
    }

    .no-print {
        display: none;
    }
}
/******************************************************
	Notes: 	this custom.scss files is for specific
			class name which cannot be found on
			general structures skins
*******************************************************/


/*------------------------------------------------------
	top brand
------------------------------------------------------*/
.top-brand {
    border-right: 1px solid #eee;
    img {
        max-height: 50px;
    }
}

.is-operator.customer-topbar,
header.is-operator {
    background-color: #fff;
}

/*------------------------------------------------------
    button
------------------------------------------------------*/
.btn {
    font-weight: 600;
}

.btn-md {
    padding: 16px 20px;
}

/*------------------------------------------------------
	top menu nav
------------------------------------------------------*/
.top-menu-main {
    .mstd-actions-dropdown {
        .dropdown-toggle,
        .btn-default:active,
        .btn-default.active,
        &.open>.btn-default.dropdown-toggle {
            @include break-sm(true) {
                border: none;

                &:hover,
                &:focus,
                &.focus {
                    border-color: darken(#000, 20%);
                }
            }
        }
    }
}

.top-menu-nav>ul>li>a,
.top-menu-nav>ul>li .dropdown-toggle {
    @include break-sm(true) {
        color: #000;
        &:hover,
        &:focus,
        &.focus {
            border-color: darken(#000, 20%);
            color: darken(#000, 20%);
        }
    }
}

.top-menu-nav>ul>li.is-selected>a {
    color: $brand-primary;
    &:hover,
    &:focus,
    &.focus {
        color: darken($brand-primary, 20%);
    }
}

.btn-default {
    border-color: $brand-primary;
    &:hover,
    &:focus,
    &.focus {
        background-color: transparent;
        border-color: $brand-primary !important;
    }
}


/*------------------------------------------------------
	login form
------------------------------------------------------*/

.login-header,
.frgtpswd-logo {
    img {
        width: 330px;
    }
}

.login-content h1 {
    text-transform: capitalize;
}

.field-validation-error {
    color: $brand-danger;
}


/*------------------------------------------------------
	summary boxes
------------------------------------------------------*/
//override table layout by removing gap

.summaryboxes {
    border-collapse: collapse;

    &-content {
        margin-right: 0;
        margin-left: 0;
    }
}

.smbxs {

    &-box {
        background-color: #fff;

        &.is-color-danger {
            background-color: #C00C0C;
        }

        &.is-color-darkgray {
            background-color: #4D4D4D;
        }

        &.is-color-info {
            background-color: #324392;
        }
    }

    &-label {
        font-size: 24px;
    }

    &-value {
        font-size: 34px;
        line-height: 34px;
        font-weight: 100;
        margin-bottom: 10px;
    }

    &-info {
        font-size: 13px;
    }

    &-paylink {
        color: #324392;
    }
}
/*######################################################
  animation
#######################################################*/
.loader {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

    @include break-sm{
        @include transform(scale(0.5));
        margin: 30px auto 20px;
    }
}
@-webkit-keyframes load5 {
 0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em $brand-primary, 
            1.8em -1.8em 0 0em rgba($brand-primary, 0.2), 
            2.5em 0em 0 0em rgba($brand-primary, 0.2), 
            1.75em 1.75em 0 0em rgba($brand-primary, 0.2), 
            0em 2.5em 0 0em rgba($brand-primary, 0.2), 
            -1.8em 1.8em 0 0em rgba($brand-primary, 0.2), 
            -2.6em 0em 0 0em rgba($brand-primary, 0.5), 
            -1.8em -1.8em 0 0em rgba($brand-primary, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($brand-primary, 0.7), 
            1.8em -1.8em 0 0em $brand-primary, 
            2.5em 0em 0 0em rgba($brand-primary, 0.2), 
            1.75em 1.75em 0 0em rgba($brand-primary, 0.2), 
            0em 2.5em 0 0em rgba($brand-primary, 0.2), 
            -1.8em 1.8em 0 0em rgba($brand-primary, 0.2), 
            -2.6em 0em 0 0em rgba($brand-primary, 0.2), 
            -1.8em -1.8em 0 0em rgba($brand-primary, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba($brand-primary, 0.5), 
            1.8em -1.8em 0 0em rgba($brand-primary, 0.7), 
            2.5em 0em 0 0em $brand-primary, 
            1.75em 1.75em 0 0em rgba($brand-primary, 0.2),
            0em 2.5em 0 0em rgba($brand-primary, 0.2), 
            -1.8em 1.8em 0 0em rgba($brand-primary, 0.2), 
            -2.6em 0em 0 0em rgba($brand-primary, 0.2), 
            -1.8em -1.8em 0 0em rgba($brand-primary, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($brand-primary, 0.2), 
            1.8em -1.8em 0 0em rgba($brand-primary, 0.5), 
            2.5em 0em 0 0em rgba($brand-primary, 0.7), 
            1.75em 1.75em 0 0em $brand-primary, 
            0em 2.5em 0 0em rgba($brand-primary, 0.2), 
            -1.8em 1.8em 0 0em rgba($brand-primary, 0.2), 
            -2.6em 0em 0 0em rgba($brand-primary, 0.2), 
            -1.8em -1.8em 0 0em rgba($brand-primary, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba($brand-primary, 0.2), 
            1.8em -1.8em 0 0em rgba($brand-primary, 0.2), 
            2.5em 0em 0 0em rgba($brand-primary, 0.5), 
            1.75em 1.75em 0 0em rgba($brand-primary, 0.7), 
            0em 2.5em 0 0em $brand-primary, 
            -1.8em 1.8em 0 0em rgba($brand-primary, 0.2), 
            -2.6em 0em 0 0em rgba($brand-primary, 0.2), 
            -1.8em -1.8em 0 0em rgba($brand-primary, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($brand-primary, 0.2), 
            1.8em -1.8em 0 0em rgba($brand-primary, 0.2), 
            2.5em 0em 0 0em rgba($brand-primary, 0.2), 
            1.75em 1.75em 0 0em rgba($brand-primary, 0.5), 
            0em 2.5em 0 0em rgba($brand-primary, 0.7), 
            -1.8em 1.8em 0 0em $brand-primary, 
            -2.6em 0em 0 0em rgba($brand-primary, 0.2), 
            -1.8em -1.8em 0 0em rgba($brand-primary, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba($brand-primary, 0.2), 
            1.8em -1.8em 0 0em rgba($brand-primary, 0.2), 
            2.5em 0em 0 0em rgba($brand-primary, 0.2), 
            1.75em 1.75em 0 0em rgba($brand-primary, 0.2), 
            0em 2.5em 0 0em rgba($brand-primary, 0.5), 
            -1.8em 1.8em 0 0em rgba($brand-primary, 0.7), 
            -2.6em 0em 0 0em $brand-primary, 
            -1.8em -1.8em 0 0em rgba($brand-primary, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($brand-primary, 0.2), 
            1.8em -1.8em 0 0em rgba($brand-primary, 0.2), 
            2.5em 0em 0 0em rgba($brand-primary, 0.2), 
            1.75em 1.75em 0 0em rgba($brand-primary, 0.2), 
            0em 2.5em 0 0em rgba($brand-primary, 0.2), 
            -1.8em 1.8em 0 0em rgba($brand-primary, 0.5), 
            -2.6em 0em 0 0em rgba($brand-primary, 0.7), 
            -1.8em -1.8em 0 0em $brand-primary;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em $brand-primary, 
            1.8em -1.8em 0 0em rgba($brand-primary, 0.2), 
            2.5em 0em 0 0em rgba($brand-primary, 0.2), 
            1.75em 1.75em 0 0em rgba($brand-primary, 0.2), 
            0em 2.5em 0 0em rgba($brand-primary, 0.2), 
            -1.8em 1.8em 0 0em rgba($brand-primary, 0.2), 
            -2.6em 0em 0 0em rgba($brand-primary, 0.5), 
            -1.8em -1.8em 0 0em rgba($brand-primary, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($brand-primary, 0.7), 
            1.8em -1.8em 0 0em $brand-primary, 
            2.5em 0em 0 0em rgba($brand-primary, 0.2), 
            1.75em 1.75em 0 0em rgba($brand-primary, 0.2), 
            0em 2.5em 0 0em rgba($brand-primary, 0.2), 
            -1.8em 1.8em 0 0em rgba($brand-primary, 0.2), 
            -2.6em 0em 0 0em rgba($brand-primary, 0.2), 
            -1.8em -1.8em 0 0em rgba($brand-primary, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba($brand-primary, 0.5), 
            1.8em -1.8em 0 0em rgba($brand-primary, 0.7), 
            2.5em 0em 0 0em $brand-primary, 
            1.75em 1.75em 0 0em rgba($brand-primary, 0.2),
            0em 2.5em 0 0em rgba($brand-primary, 0.2), 
            -1.8em 1.8em 0 0em rgba($brand-primary, 0.2), 
            -2.6em 0em 0 0em rgba($brand-primary, 0.2), 
            -1.8em -1.8em 0 0em rgba($brand-primary, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($brand-primary, 0.2), 
            1.8em -1.8em 0 0em rgba($brand-primary, 0.5), 
            2.5em 0em 0 0em rgba($brand-primary, 0.7), 
            1.75em 1.75em 0 0em $brand-primary, 
            0em 2.5em 0 0em rgba($brand-primary, 0.2), 
            -1.8em 1.8em 0 0em rgba($brand-primary, 0.2), 
            -2.6em 0em 0 0em rgba($brand-primary, 0.2), 
            -1.8em -1.8em 0 0em rgba($brand-primary, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba($brand-primary, 0.2), 
            1.8em -1.8em 0 0em rgba($brand-primary, 0.2), 
            2.5em 0em 0 0em rgba($brand-primary, 0.5), 
            1.75em 1.75em 0 0em rgba($brand-primary, 0.7), 
            0em 2.5em 0 0em $brand-primary, 
            -1.8em 1.8em 0 0em rgba($brand-primary, 0.2), 
            -2.6em 0em 0 0em rgba($brand-primary, 0.2), 
            -1.8em -1.8em 0 0em rgba($brand-primary, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($brand-primary, 0.2), 
            1.8em -1.8em 0 0em rgba($brand-primary, 0.2), 
            2.5em 0em 0 0em rgba($brand-primary, 0.2), 
            1.75em 1.75em 0 0em rgba($brand-primary, 0.5), 
            0em 2.5em 0 0em rgba($brand-primary, 0.7), 
            -1.8em 1.8em 0 0em $brand-primary, 
            -2.6em 0em 0 0em rgba($brand-primary, 0.2), 
            -1.8em -1.8em 0 0em rgba($brand-primary, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba($brand-primary, 0.2), 
            1.8em -1.8em 0 0em rgba($brand-primary, 0.2), 
            2.5em 0em 0 0em rgba($brand-primary, 0.2), 
            1.75em 1.75em 0 0em rgba($brand-primary, 0.2), 
            0em 2.5em 0 0em rgba($brand-primary, 0.5), 
            -1.8em 1.8em 0 0em rgba($brand-primary, 0.7), 
            -2.6em 0em 0 0em $brand-primary, 
            -1.8em -1.8em 0 0em rgba($brand-primary, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($brand-primary, 0.2), 
            1.8em -1.8em 0 0em rgba($brand-primary, 0.2), 
            2.5em 0em 0 0em rgba($brand-primary, 0.2), 
            1.75em 1.75em 0 0em rgba($brand-primary, 0.2), 
            0em 2.5em 0 0em rgba($brand-primary, 0.2), 
            -1.8em 1.8em 0 0em rgba($brand-primary, 0.5), 
            -2.6em 0em 0 0em rgba($brand-primary, 0.7), 
            -1.8em -1.8em 0 0em $brand-primary;
  }
}
